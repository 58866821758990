* {
    margin: 0;
    padding: 0;
}
:root:root {
    --adm-color-primary:#3236FF;
    --adm-color-warning:#F4E1C5;
    .adm-form {
        --border-top: none;
        --border-bottom: none;
    }
}

